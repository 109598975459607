<!-- eslint-disable vue/no-v-html -->
<template>
	<v-card class="pa-3">
		<!-- General informations -->
		<w-layout v-if="value.time_allocation" column>
			<v-subheader v-t="'general_information'" />
			<w-divider :color="$vuetify.theme.primary" />
			<w-flex v-if="value.price" mt-2 mb-1 px-3 shrink>
				<u>{{ $t('price') }} :</u> {{ $n(value.price, 'currency') }}
			</w-flex>
			<w-flex v-if="value.time_allocation" mt-1 px-3 shrink>
				<u>{{ $t('offers.time_allocation') }} :</u>
				{{
					value.time_allocation
						? `${(value.time_allocation - (value.time_allocation % 60)) / 60}h${(value.time_allocation % 60).toString().padStart(2, '0')}`
						: null
				}}
			</w-flex>
		</w-layout>
		<!-- Commercial description -->
		<w-layout v-if="value.commercial_description" column>
			<v-subheader v-t="'offers.commercial_description'" />
			<v-divider :color="$vuetify.theme.primary" />
			<w-flex px-2 justify-space-around v-html="value.commercial_description" />
		</w-layout>
		<!-- Contractual description -->
		<w-layout v-if="value.contractual_description" column>
			<v-subheader v-t="'offers.contractual_description'" />
			<v-divider :color="$vuetify.theme.primary" />
			<w-flex px-2 justify-space-around v-html="value.contractual_description" />
		</w-layout>
		<!-- Deliverable -->
		<w-layout v-if="value.deliverable" column>
			<v-subheader v-t="'deliverable'" />
			<v-divider :color="$vuetify.theme.primary" />
			<w-flex px-2 justify-space-around>
				<v-text-field hide-selected hide-details full-width readonly :label="$t('deliverable_type')" :value="value.deliverable.name" />
			</w-flex>
		</w-layout>
		<!-- Tasks -->
		<w-layout column>
			<v-subheader v-text="$tc('offers.tasks', value.task_models.length)" />
			<v-divider :color="$vuetify.theme.primary" />
			<w-text-info
				:text="
					$t('offers.the_task_will_be_created', {
						date: frequencyInitValue
					})
				"
			/>
			<w-layout px-2>
				<TasksList v-if="value.task_models && value.task_models.length > 0" :tasks="value.task_models" :readonly="true" @update-tasks="updateTasks" />
				<span v-else>{{ $t('offers.no_task') }}</span>
			</w-layout>
		</w-layout>
	</v-card>
</template>

<script>
export default {
	name: 'CatalogServiceDetails',
	components: {
		TasksList: () => ({
			component: import('@/components/Offers/TasksList')
		})
	},
	props: {
		readonly: {
			default: true,
			type: Boolean
		},
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			deletingCatalogService: false,
			loading: false,
			updatingCatalogService: false
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (newValue) {
				this.$emit('input', newValue)
			}
		},
		frequencyInitValue: function () {
			const value = this.model.task_creation_date_value ?? 1
			const unit = this.model.task_creation_date_unit?.name ?? 'day'

			return `${value} ${this.$tc(`offers.frequencies.task_creation_date.${unit}`, value)}`
		}
	},
	methods: {
		updateTasks: function (newValue) {
			this.model.task_models = newValue
		}
	}
}
</script>
